import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  HostBinding,
  HostListener,
  inject,
  OnInit,
  signal,
  ViewChild,
  ViewContainerRef
} from '@angular/core'
import {
  catchError,
  combineLatest,
  delay,
  EMPTY,
  filter,
  finalize,
  fromEvent,
  map,
  Observable,
  take
} from 'rxjs'
import { NzNotificationService } from 'ng-zorro-antd/notification'
import { Store } from '@ngxs/store'
import { ErrorState } from '../store/error/error.state'
import { AuthState } from '../store/auth/auth.state'
import { PreferenceState } from '../store/preference/preference.state'
import { TranslateService } from '@ngx-translate/core'
import moment from 'moment/moment'
import { registerLocaleData } from '@angular/common'
import { UserState } from '../store/user/user.state'
import { UserInterface } from '../shared/model/user.model'
import {
  Event as NavigationEvent,
  NavigationStart,
  Router,
  RouterEvent
} from '@angular/router'
import { NotificationState } from '../store/notification/notification.state'
import { NzModalService } from 'ng-zorro-antd/modal'
import { routingAnimation } from '../shared/animations/routing-animation'
import { ScreenBlockedStatus } from '../shared/model/preference.model'
import { AnimationOptions } from 'ngx-lottie'
import { PccState } from '../store/pcc/pcc.state'
import { TestModeComponent } from './test-mode/test-mode.component'
import { environment } from '../environments/environment'
import { RootStore } from '../store/root-store'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { NetworkService } from '../shared/services/network.service'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [routingAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class AppComponent implements OnInit, AfterViewInit {
  private readonly errorState = inject(ErrorState)
  private readonly notification = inject(NzNotificationService)
  private readonly translate = inject(TranslateService)
  private readonly notificationState = inject(NotificationState)
  private readonly modal = inject(NzModalService)
  private readonly userState = inject(UserState)
  private readonly networkService = inject(NetworkService)
  private readonly pccState = inject(PccState)
  public readonly authState = inject(AuthState)
  public readonly preferenceState = inject(PreferenceState)
  public readonly router = inject(Router)
  private readonly destroyRef = inject(DestroyRef)
  private readonly store = inject(Store)

  errorMessage$: Observable<string | null> = this.store.select(
    ErrorState.errorMessage
  )
  isAuthorized$: Observable<boolean> = this.store.select(
    AuthState.isAuthenticated
  )
  userProfile$: Observable<any> = this.store.select(UserState.userProfile)
  language$ = this.store.select(PreferenceState.language)
  user$: Observable<UserInterface | null> = this.store.select(
    UserState.currentUser
  )
  isMfaRequired$: Observable<boolean> = this.store.select(
    PreferenceState.isMfaRequired
  )
  isSessionExpire$: Observable<boolean> = this.store.select(
    PreferenceState.sessionExpire
  )
  notification$ = this.store.select(PreferenceState.notification)
  screenSaver$: Observable<boolean> = this.store.select(
    PreferenceState.screenSaver
  )
  pccLoginSettingsTabletErrorShown$ = this.store.select(
    AuthState.pccLoginSettingsTabletErrorShown
  )
  pccLoginWasNotPerformedButClosed$: Observable<boolean> = this.store.select(
    AuthState.pccLoginWasNotPerformedButClosed
  )
  pccLoginWasPerformedWithCNAUser$: Observable<boolean> = this.store.select(
    PreferenceState.pccLoginWasPerformedWithCNAUser
  )
  screenBlockedStatus$: Observable<string> = this.store.select(
    RootStore.screenBlockedStatus
  )
  isForcedResolution$: Observable<boolean | undefined> = this.store.select(
    PreferenceState.isForcedResolution
  )
  incorrectTimeSystem$: Observable<boolean> = this.store.select(
    PreferenceState.incorrectTimeSystem
  )
  hasNotificationAccess$: Observable<boolean> = this.store.select(
    PreferenceState.hasNotificationAccess
  )
  userShouldWatchDisclaimer = this.store.selectSignal(
    UserState.isShouldWatchDisclaimer
  )
  hasVirtualKeyboard = this.store.selectSignal(
    PreferenceState.hasVirtualKeyboard
  )
  isUserWithNoDegree = this.store.selectSignal(AuthState.isUserWithNoDegree)
  nonDegreeUserSelectedCNA = this.store.selectSignal(
    PreferenceState.nonDegreeUserSelectedCNA
  )

  isCollapseMenu: boolean = true
  isSessionExpired: boolean = false
  ScreenBlockedStatus = ScreenBlockedStatus
  options: AnimationOptions = {
    path: '/assets/animation.json'
  }
  public urlLogin$ = this.router.events.pipe(
    filter(
      (event: NavigationEvent | RouterEvent) => event instanceof NavigationStart
    ),
    // @ts-ignore
    map(e => e.url !== '/login')
  )
  @ViewChild('testModeContainer', { read: ViewContainerRef })
  testModeContainer!: ViewContainerRef
  isShowClockUpdateModal = false
  public pccLoginReturning = signal<boolean>(false)
  protected readonly pccLogouting$ = this.pccState.pccLogouting$
  protected pccLoginErrorTechnicalDetailsVisible: boolean = false
  protected readonly isMobile = this.preferenceState.isMobile
  protected readonly isOffline$ = this.networkService.isOfflineDebounced$
  @HostBinding('@routingAnimation') private routing: any

  constructor() {
    const translate = this.translate

    translate.setDefaultLang('en')
    this.controlDeviceSleepReturning()
    this.handleNativeDeviceVirtualKeyboardExistence()
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.setWindowZoom()
  }

  ngOnInit(): void {
    this.initializeListeners()
    this.pccBroadcastChannelListener()
    this.setPccLoginReturningLoader()

    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'visible') {
        this.authState.checkTokenStatus()
      }
    })
  }

  ngAfterViewInit() {
    if (environment?.test) {
      this.addTestModeComponent()
    }
  }

  addTestModeComponent() {
    this.testModeContainer.clear()
    this.testModeContainer.createComponent(TestModeComponent)
  }

  initializeListeners(): void {
    this.setMfaListenerSetting()
    this.errorMessageListenerSetting()
    this.languageListenerSetting()
    this.setNotificationPermissionSetting()
    this.setRouterConfigLogic()
    this.setWindowZoom()
    this.authState.checkTokenStatus()
  }

  setWindowZoom() {
    if (this.isMobile) return
    // @ts-ignore
    // document.body.style.zoom = window.innerWidth / 1920
  }

  errorMessageListenerSetting() {
    this.errorMessage$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((message: string | null) => {
        if (!message) return
        this.notification.create('error', 'Error', message)
        setTimeout(() => this.errorState.clear(), 5000)
      })
  }

  setMfaListenerSetting() {
    this.isMfaRequired$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(mfaRequired => {
        this.authState.patchState({
          mfaRequired
        })
      })
  }

  languageListenerSetting() {
    this.language$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((data: string | null) => {
        if (data) {
          moment.locale(data)
          registerLocaleData(data)
          this.translate.use(data)
          return
        }
        this.preferenceState.setLanguage(
          // @ts-ignore
          navigator.language !== 'en' || navigator.language !== 'es'
            ? 'en'
            : navigator.language
        )
      })
  }

  setNotificationPermissionSetting() {
    combineLatest(this.userProfile$, this.notification$)
      .pipe(delay(2000), takeUntilDestroyed(this.destroyRef), take(2))
      .subscribe(data => {
        let [user, notification] = [data[0], data[1]]
        if (!user) return
        if (typeof notification === 'object') {
          this.modal.confirm({
            nzTitle: 'Do you want to receive notification for alerts?',
            nzOnOk: () => {
              this.preferenceState.setNotification(true)
              this.setSoundNotificationsModalSetting()
            },
            nzOnCancel: () => {
              this.preferenceState.setNotification(false)
            }
          })
        }
      })

    combineLatest(this.userProfile$, this.notification$)
      .pipe(delay(2000), takeUntilDestroyed(this.destroyRef))
      .subscribe(data => {
        let [user, notification] = [data[0], data[1]]
        if (!user) return
        if (typeof notification === 'boolean' && notification) {
          this.notificationState.requestPermission()
        }
      })
  }

  setSoundNotificationsModalSetting() {
    this.modal.confirm({
      nzTitle: 'Do you want to receive sound notifications for alerts?',
      nzOnOk: () => {
        navigator.mediaDevices
          .getUserMedia({ audio: true })
          .then(stream => {
            this.preferenceState.setPreferenceSoundNotifications(true)
          })
          .catch(error =>
            this.preferenceState.setPreferenceSoundNotifications(false)
          )
      },
      nzOnCancel: () => {
        this.preferenceState.setPreferenceSoundNotifications(false)
      }
    })
  }

  handlerCollapseMenuEmitter($event: boolean): void {
    this.preferenceState.setPreferenceIsCollapseMenu($event)
  }

  setRouterConfigLogic() {
    if (this.isMobile) return
    // this.router.resetConfig([...MobileRoutes])
  }

  public screenSaverClick(event: Event): void {
    if (this.isMobile) {
      event.stopPropagation()
    }
  }

  public hidePccLoginSettingsTabletError(): void {
    this.authState.pccLoginSettingsTabletErrorShow(null)
  }

  public pccLoginWasNotPerformedButClosed(): void {
    this.authState.pccLoginWasNotPerformedButClosed(false)
    if (this.authState.accessToken()) {
      this.authState.handlePccLoginWindowClosing()
    }
  }

  public pccLoginWasPerformedWithCNAUserClose(): void {
    this.preferenceState.setPccLoginWasPerformedWithCNAUser(false)
  }

  public setNonDegreeUserSelectedCNA(): void {
    this.preferenceState.setNonDegreeUserSelectedCNA(false)
  }

  public handlerLoginWithPcc(): void {
    this.authState.pccLoginWasNotPerformedButClosed(false)
    this.authState.loginWithPcc(
      !this.authState.accessToken() ? 'login-tablet' : 'tablet'
    )
  }

  public declineDisclaimerLogout(): void {
    this.pccState
      .pccLogout()
      .pipe(
        take(1),
        catchError(er => {
          console.error(er)
          return EMPTY
        }),
        finalize(() => {
          this.authState.logout()
          this.userState.setShouldWatchDisclaimer(false)
        })
      )
      .subscribe()
  }

  public acceptDisclaimer(): void {
    this.userState.acceptSignDisclaimer()
  }

  hideModal() {
    this.preferenceState.setSessionExpire(false)
  }

  handlerScreenSaverSubmitEmitter() {
    this.preferenceState.setScreenSever(false)
  }

  handlerCloseClockUpdateModal($event: boolean) {
    this.preferenceState.setIncorrectTimeSystem($event)
  }

  private pccBroadcastChannelListener() {
    const bc = new BroadcastChannel('aiomed_pcc_channel')
    bc.postMessage('loggedToPCC')
  }

  private controlDeviceSleepReturning(): void {
    if (!this.isMobile) {
      return
    }

    let timeHidden = new Date()
    let timeVisible = new Date()
    document.addEventListener('visibilitychange', () => {
      if (document.hidden) {
        timeHidden = new Date()
      } else {
        timeVisible = new Date()
      }
      if (!this.authState.isAuthenticated()) {
        return
      }

      if (!document.hidden) {
        this.authState.checkTokenStatus()
      }
      const differenceInMinutes = Math.abs(
        moment(timeVisible).diff(moment(timeHidden), 'minutes')
      )
      const IDLE_TIME_MINUTES = 2
      const RN_IDLE_TIME = 20
      if (differenceInMinutes >= IDLE_TIME_MINUTES && !document.hidden) {
        this.authState.handleSleepModeReturning(differenceInMinutes)
      }
    })
  }

  private handleNativeDeviceVirtualKeyboardExistence(): void {
    if (navigator && navigator.maxTouchPoints && navigator.maxTouchPoints > 1) {
      this.preferenceState.setVirtualKeyboardPresence()
    }

    if (!('virtualKeyboard' in navigator)) {
      return
    }

    fromEvent((navigator as any).virtualKeyboard, 'geometrychange')
      .pipe(takeUntilDestroyed())
      .subscribe(() => {
        // VirtualKeyboardGeometryChange
        this.preferenceState.setVirtualKeyboardPresence()
      })
  }

  private setPccLoginReturningLoader(): void {
    const urlParams = new URLSearchParams(document?.location?.search)
    const loading = urlParams.get('loading')

    if (!loading) {
      return
    }

    this.pccLoginReturning.set(true)
    setTimeout(() => {
      this.pccLoginReturning.set(false)
    }, 3000)
  }
}
